/* Project specific Javascript goes here. */

import * as bootstrap from "bootstrap";
import Dropzone from "dropzone";
import htmx from "htmx.org/dist/htmx";
import "../scss/project.scss";

window.Dropzone = Dropzone;
Dropzone.autoDiscover = false;

window.htmx = htmx;
window.bootstrap = bootstrap;
